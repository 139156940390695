<template>
  <div>
    <div class="mian">
      <div class="title">维修内容</div>
      <div class="item">
        <span class="itemLabel">故障类型</span
        ><span class="itemValue">
          {{ report.repairType ? getStatusTitle(report.repairType) : "-" }}
        </span>
      </div>
      <div class="item">
        <span class="itemLabel">故障原因</span
        ><span class="itemValue"> {{ report.repairDetail || "-" }} </span>
      </div>
    </div>
    <div class="mian">
      <div class="title">维修结果</div>
      <div class="textarea">故障现象：{{ report.repairResult }}</div>
      <div class="imageList" v-if="report.photos2 && report.photos2.length > 0">
        <div
          class="imageItem"
          v-for="(item, index) in report.photos2"
          :key="item.id"
        >
          <img
            :src="$replacePhoto(item.url)"
            class="userImg"
            @click="showBigImage(report.photos2, index)"
          />
        </div>
      </div>
      <div class="noPhoto" v-else>暂无处理图片</div>
    </div>
    <div class="mian" v-show="!hideRemark">
      <div class="title">处理意见</div>
      <div class="textarea">
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 6 }"
          placeholder="请描述故障情况"
          v-model="remark"
        >
        </el-input>
      </div>
    </div>
    <van-image-preview
      v-model="showImage"
      :images="images"
      :startPosition="imagesIdx"
      :closeable="true"
    >
    </van-image-preview>
  </div>
</template>

<script>
import deviceWorkerRepairOptions from "@/components/deviceWorkerRepairOptions/deviceWorkerRepairOptions.js";
export default {
  mixins: [deviceWorkerRepairOptions],
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    hideRemark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      remark: "",
      imagesIdx: 0,
      images: [],
      showImage: false,
    };
  },
  created() {},
  methods: {
    showBigImage(photo, index) {
      this.images = [];
      this.imagesIdx = index;
      for (let i = 0; i < photo.length; i++) {
        let data = photo[i];
        this.images.push(this.$replacePhoto(data.url));
      }
      this.showImage = true;
    },
  },
};
</script>
<style lang='scss' scoped>

@import "@styles/variables.scss";

.mian {
  background: $background_color2;
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 10px;
  .item {
    font-size: 14px;
    height: 38px;
    line-height: 37px;
    .itemLabel {
      color: $color3;
    }
    .itemValue {
      float: right;
      .inputView {
        width: 132px;
        float: left;
        font-size: 14px;
      }
      .scanning {
        color: #3e73fb;
        img {
          width: 16px;
          vertical-align: sub;
        }
      }
    }
  }
  .title {
    font-size: 14px;
    color: $color3;
    padding-bottom: 6px;
    color: $color1;
    font-weight: 500;
  }
  .textarea {
    font-size: 12px;
    color: $color3;
  }
  .imageList {
    display: flex;
    overflow: scroll;
    margin-top: 10px;
    .imageItem {
      margin-right: 10px;
      position: relative;
      .userImg {
        width: 104px;
        height: 104px;
        border-radius: 2px;
      }
      .viewerClose {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        line-height: 24px;
        text-align: center;
        right: 4px;
        top: 4px;
        background: rgba($color: #323233, $alpha: 0.2);
        color: #ffffff;
      }
    }
  }
  .noPhoto {
    padding: 36px 0;
    text-align: center;
    color: $color3;
  }
}

::v-deep .el-textarea__inner {
  padding: 5px 10px;
  border: 0 none;
  background: #fbfbfb;
}

::v-deep .van-image {
  scale: 0.8;
}

</style>